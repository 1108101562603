// App.js
import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; 
import TermsAndConditions from './TermsAndConditions'; 
import Privacy from './Privacy'
import Slider from 'react-slick';
import img1 from './assets/images/2.png'; 
import img2 from './assets/images/1.png';
import img3 from './assets/images/3.png';

function App() {
  // const [loading, setLoading] = useState(true);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    // autoplaySpeed: 3000,
    swipe: true,
    arrows: false
  };

  // useEffect(() => {
  //   // Simulazione di un caricamento (puoi usare un tempo reale di caricamento o eventi di completamento)
  //   const timer = setTimeout(() => {
  //     setLoading(false); // Il caricamento è terminato
  //   }, 2000); // Imposta un tempo di attesa di 2 secondi (modificabile)

  //   return () => clearTimeout(timer); // Pulisce il timeout al termine
  // }, []);

  // if (loading) {
  //   return (
  //     <div className="loading-screen">
  //       <h2>Loading...</h2>
  //     </div>
  //   );
  // }
  return (
    <Router>
      <div className="container">
        <header>
          <h1>ATMOOSS</h1>
        </header>

        <Routes>
          {/* Definisci la route per la home page */}
          <Route path="/" element={
            <>
            <h2>WEEKEND AWAY - WINTER EDITION</h2>
            <p className="underline">
          QUI DI SEGUITO TROVERAI TUTTI I DETTAGLI RELATIVI A “WEEKEND AWAY | WINTER EDITION”. PER ULTERIORI INFORMAZIONI O PER ISCRIVERTI CONTATTACI SU INSTAGRAM A QUESTO 
          <a href="https://www.instagram.com/atmooss" target="_blank" rel="noopener noreferrer"> LINK</a>.
          </p>
              <section className="details">
                <div className="info">
                  <p>📅 <strong>Data:</strong> 30 Novembre-1 Dicembre 2024</p>
                  <p>📍 <strong>Luogo:</strong> Casa in montagna a 2 ore da Milano (170km)</p>
                  <p>🔞 <strong>Range d'età:</strong> 20-35 anni</p>
                </div>
              </section>

              <section className="description">
                <h3>Descrizione dell'evento</h3>
                <p>
                  Immagina di trascorrere un weekend in una casa di montagna, avvolto 
                  dall'atmosfera natalizia e (se saremo fortunati) da una bella nevicata. 
                  L'idea alla base è quella di trascorrere del tempo lontano dalla frenesia 
                  dei social e dalle distrazioni quotidiane, in compagnia di persone che, 
                  come te, desiderano immergersi nella realtà e vivere dei momenti autentici.
                </p>
                <p>⌚️ La partenza è prevista per sabato 30 novembre alle ore 09:00 presso Romolo M2. Il ritorno a Milano è previsto per domenica 01 dicembre alle ore 17:00.</p>
              </section>

              <section className="cost">
                <h3>Quota di partecipazione</h3>
                <p className="underline">La quota di partecipazione, pari a 135€, include:</p>
                <ul>
                  <li>🏡  Alloggio: Pernottamento in una casa completamente a nostra disposizione e immersa nelle montagne bresciane (800m slm).</li>
                  <li>🍽️  Pasti e bevande: Pranzo e cena di sabato + colazione e pranzo di domenica.</li>
                  <li>🚗   Trasporti: Trasferimenti A/R Milano (*leggi il paragrafo "Note importanti").</li>
                  <li>🎵 Attività e intrattenimento.</li>
                  <br />
                  <li className = "underline">Politica di cancellazione: Cancellazione gratuita con rimborso 100% fino a 15 giorni prima della partenza (entro il 15/11/2024).</li>
                </ul>
              </section>
              <section className="pagamento">
              <h3>Modalità di pagamento</h3>
              <p>La conferma d’iscrizione avviene solo dopo la ricezione della quota di partecipazione, da versare in un’unica soluzione sotto forma di bonifico bancario.  </p>
              </section>

              <section className="what-to-bring">
                <h3>Cosa portare</h3>
                <ul>
                  <li>Vestiti comodi per tutte le attività.</li>
                  <li>Lenzuola, coperte e/o sacco a pelo.</li>
                  <li>Asciugamani.</li>
                  <li>Un documento d'identità in corso di validità.</li>
                </ul>
              </section>

              <section>
                <h3> Note importanti</h3>
                <ul>
                <li className='underline'>Si prega di comunicare eventuali allergie e/o intolleranze alimentari.</li>
                  <li>È possibile partecipare da soli o in compagnia.</li>
                  <li>Il team ATMOOSS sarà a disposizione dall'inizio alla fine del weekend per qualsiasi problema.</li>
                  <li>In caso di assenza comunicata oltre il 15 novembre 2024, non sarà possibile ottenere un rimborso.</li>
                  <li>Dopo la conferma, verrai aggiunto ad un gruppo WhatsApp per organizzare al meglio il weekend.</li>
                  <li>La casa offre letti a castello suddivisi in varie camerate più o meno grandi, la scelta del posto è assolutamente a discrezione di ogni partecipante. </li>
                  <li> *Ti verrà chiesto se intendi mettere a disposizione la tua auto, in caso affermativo, il team ATMOOSS ti fornirà tutte le informazioni utili (alcune iscrizioni potrebbero essere vincolate all’utilizzo dell’auto per permettere il raggiungimento della copertura totale). 
                    Il trasporto è da intendersi in totale responsabilità del conducente che garantisce ed accetta i termini e condizioni di ATMOOSS. 
                    Ci si troverà al luogo di ritrovo per partire tutti insieme, ogni partecipante sarà libero di scegliere quale trasporto utilizzare. </li>

                </ul>
              </section>

              <Slider {...settings} className="image-slider">
                <div>
                  <img src={img1} />
                </div>
                <div>
                  <img src={img2} />
                </div>
                <div>
                  <img src={img3} />
                </div>
              </Slider>
              <footer>
                <p>Per motivi di sicurezza, tutti i partecipanti saranno accuratamente selezionati dal team ATMOOSS.</p>
                <p>
                  <Link target="_blank" rel="noopener noreferrer" to="/terms-and-conditions">Termini e Condizioni</Link> | 
                  <Link target="_blank" rel="noopener noreferrer" to="/privacy">Privacy Policy</Link>
                </p>
              </footer>
            </>
          } />

          {/* Definisci la route per "Termini e Condizioni" */}
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </div>
    </Router>
  );
}
export default App;
